import React, { useEffect, useRef } from 'react';
import styled from 'styled-components';
import { useInView } from 'react-intersection-observer';
import { gsap } from 'gsap';

import DesksSvgComponent from '@illustration-components/desks.svg';
import { frameAnimation } from '@mixins';
import { isBrowser } from '@utils';
import { mqdown } from '@mq';

const DesksIllustration = ({ mobile }) => {
  const animation = useRef(null);

  const { ref, inView } = useInView({
    threshold: 0,
    rootMargin: '-20% 0px -20% 0px',
  });

  useEffect(
    () =>
      // eslint-disable-next-line consistent-return
      () => {
        if (animation.current) {
          animation.current.kill();
        }
      },
    []
  );

  useEffect(() => {
    animation.current = gsap.timeline({});
    animation.current.fromTo(
      `${mobile ? `.mobile` : `.regular`} .space-star-desks`,
      0.1,
      { opacity: 0 },
      {
        opacity: 1,
        stagger: {
          each: 1,
          from: 'top right',
        },
      }
    );
    animation.current.pause();
  }, [mobile]);

  useEffect(() => {
    if (inView) {
      animation.current.play();
    } else {
      animation.current.pause();
    }
  }, [inView]);

  if (!isBrowser) {
    return null;
  }

  return (
    <Container ref={ref} inView={inView} className={mobile ? 'mobile' : 'regular'}>
      <DesksSvgComponent />
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  ${frameAnimation};

  ${mqdown.small} {
    margin-top: calc((var(--space-s) - var(--space-xxxs)) * -1);
    margin-bottom: calc(var(--space-xs) * -1);
  }
`;

export default DesksIllustration;
